import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import Store from "./redux/store/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import TestChat from "./modules/ChatComponents/ChatRoom/TestChat";
import { WebSocketProvider } from "./context/WebSocketContext";
require("./services/axios");
ReactDOM.render(
    <BrowserRouter>
      <App /> <ToastContainer />
    </BrowserRouter>
  ,
  document.getElementById("root")
);

reportWebVitals();
