import { IconButton } from "@mui/material";
import {
  Camera,
  Envelope,
  FileText,
  GlobeSimple,
  MapPinLine,
  Pencil,
  Phone,
  User,
} from "phosphor-react";
import React from "react";
import profile from "../../../../assets/logo/ProfileImg.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import newProfile from "../../../../assets/logo/iProfile.jpg";
import "./Profile.css";
import { useState } from "react";
import { BASE_URL } from "../../../Const/Url";
import axios from "axios";
import { useEffect } from "react";
import { showToast } from "../../../../utils/ToastHelper";
import MainLoader from "../../../Authentication/MainLoader";
const EditProfile = ({ setRender }) => {
  const storageData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storageData);
  const [isLoading, setIsLoading] = useState(true);
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editMobile, setEditMobile] = useState(false);
  const [editWebsite, setEditWebsite] = useState(false);
  const [editBio, setEditBio] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [name, setName] = useState("");
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    bio: "",
    image_url: null,
    website: "",
    address: "",
    mobile: "",
    email: "",
  });
  const userProfile = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        setName(`${res.data.data.first_name} ${res.data.data.last_name}`);
        setUserData({
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          bio: res.data.data.bio,
          image_url: res.data.data.image_url,
          email: res.data.data.email,
          address: res.data.data.address,
          website: res.data.data.website,
          mobile: res.data.data.mobile
        });
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };
  useEffect(() => {
    userProfile();
  }, []);

  const saveData = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .post(url, userData)
      .then((res) => {
        if (res.data.status) {
          userProfile();
          setEditName(false);
          setEditBio(false);
          setEditEmail(false);
          setEditAddress(false);
          setEditWebsite(false);
          setEditMobile(false);
          showToast("success", "Data Updated Successfully.");
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        showToast("error", message);
      });
  };
  if (isLoading) {
    return (
      <div>
        <MainLoader />
      </div>
    );
  }
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "24px" }}>Edit Profile </h3>
        </div>
      </div>
      <div className="user_profile user_profile2">
        <div className="user_img_div user_profile_new_div">
          <img className="img-fluid " src={userData?.image_url} alt="" />
        </div>
        <h2>{name}</h2>
      </div>
      <div className="user_info ">
        <div className="name edit">
          <div className="d-flex align-items-center">
            <div
              className="edit_icon"
              style={{ background: "#1F5DA0", padding: "4px" }}
            >
              <User size={24} weight="light" color="white" />
            </div>
            {editName === true ? (
              <div className="edit_name_div">
                <input
                  type="text"
                  value={userData?.first_name}
                  onChange={(e) =>
                    setUserData({ ...userData, first_name: e.target.value })
                  }
                  placeholder="First Name"
                />
                <input
                  className="ms-2"
                  value={userData?.last_name}
                  onChange={(e) =>
                    setUserData({ ...userData, last_name: e.target.value })
                  }
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            ) : (
              <h5>
                {userData.first_name} {userData.last_name}
              </h5>
            )}
          </div>
          {editName === false && (
            <div
              className="edit_icon "
              style={{ padding: "6px 6px", }}
              onClick={() => setEditName(true)}
            >
              <Pencil size={20} weight="light" color="#1D1D1DBF" />
            </div>
          )}
        </div>
        <hr />
        <div className="name edit">
          <div className="d-flex align-items-center">
            <div
              className="edit_icon"
              style={{ background: "#1F5DA0", padding: "4px" }}
            >
              <Phone size={24} weight="light" color="white" />
            </div>
            {editMobile === true ? (
              <div className=" single_input">
                <input
                  style={{ minWidth: "80%" }}
                  onChange={(e) =>
                    setUserData({ ...userData, mobile: e.target.value })
                  }
                  type="text"
                  value={userData?.mobile}
                  placeholder="Add Mobile"
                />
              </div>
            ) : (
              <>
                {userData?.mobile ? (
                  <h5>{userData?.mobile}</h5>
                ) : (
                  <h5 style={{ color: " #1D1D1D4D" }}>Email</h5>
                )}
              </>
            )}
          </div>
          {editMobile === false && (
            <div
              className="edit_icon "
              onClick={() => setEditMobile(true)}
              style={{ padding: "6px 6px", marginLeft: "40px" }}
            >
              <Pencil size={20} weight="light" color="#1D1D1DBF" />
            </div>
          )}
        </div>
        <hr />
        <div className="name edit">
          <div className="d-flex align-items-center">
            <div
              className="edit_icon"
              style={{ background: "#1F5DA0", padding: "4px" }}
            >
              <Envelope size={24} weight="light" color="white" />
            </div>
            {editEmail === true ? (
              <div className=" single_input">
                <input
                  style={{ minWidth: "80%" }}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  type="text"
                  value={userData?.email}
                  placeholder="Add Email"
                />
              </div>
            ) : (
              <>
                {userData?.email ? (
                  <h5>{userData?.email}</h5>
                ) : (
                  <h5 style={{ color: " #1D1D1D4D" }}>Email</h5>
                )}
              </>
            )}
          </div>
          {editEmail === false && (
            <div
              className="edit_icon "
              style={{ padding: "6px", marginLeft: "40px" }}
              onClick={() => setEditEmail(true)}
            >
              <Pencil size={20} weight="light" color="#1D1D1DBF" />
            </div>
          )}
        </div>
        <hr />
        <div className="name edit">
          <div className="d-flex align-items-center">
            <div
              className="edit_icon"
              style={{ background: "#1F5DA0", padding: "4px" }}
            >
              <GlobeSimple size={24} weight="light" color="white" />
            </div>
            {editWebsite === true ? (
              <div className=" single_input">
                <input
                  style={{ minWidth: "80%" }}
                  onChange={(e) =>
                    setUserData({ ...userData, website: e.target.value })
                  }
                  type="text"
                  value={userData?.website}
                  placeholder="Add Website"
                />
              </div>
            ) : (
              <>
                {userData?.website ? (
                  <h5>{userData?.website}</h5>
                ) : (
                  <h5 style={{ color: " #1D1D1D4D" }}>Website</h5>
                )}
              </>
            )}
          </div>
          {
            editWebsite === false && (
              <div
                className="edit_icon "
                onClick={() => setEditWebsite(true)}
                style={{ padding: "6px", marginLeft: "40px" }}
              >
                <Pencil size={20} weight="light" color="#1D1D1DBF" />
              </div>
            )
          }

        </div>
        <hr />
        <div className="name edit">
          <div className="d-flex align-items-center">
            <div
              className="edit_icon"
              style={{ background: "#1F5DA0", padding: "4px" }}
            >
              <FileText size={24} weight="light" color="white" />
            </div>
            {editBio === true ? (
              <div className=" single_input">
                <input
                  style={{ minWidth: "80%" }}
                  onChange={(e) =>
                    setUserData({ ...userData, bio: e.target.value })
                  }
                  type="text"
                  value={userData?.bio}
                  placeholder="Add Bio"
                />
              </div>
            ) : (
              <>
                {userData?.bio ? (
                  <h5>{userData.bio}</h5>
                ) : (
                  <h5 style={{ color: " #1D1D1D4D" }}>Bio</h5>
                )}
              </>
            )}
          </div>
          {editBio === false && (
            <div
              className="edit_icon "
              style={{ padding: "6px", marginLeft: "40px" }}
              onClick={() => setEditBio(true)}
            >
              <Pencil size={20} weight="light" color="#1D1D1DBF" />
            </div>
          )}
        </div>
        <hr />
        <div className="name edit">
          <div className="d-flex align-items-center">
            <div
              className="edit_icon"
              style={{ background: "#1F5DA0", padding: "4px" }}
            >
              <MapPinLine size={24} weight="light" color="white" />
            </div>
            {editAddress === true ? (
              <div className=" single_input">
                <input
                  style={{ minWidth: "80%" }}
                  onChange={(e) =>
                    setUserData({ ...userData, address: e.target.value })
                  }
                  type="text"
                  value={userData?.address}
                  placeholder="Add Address"
                />
              </div>
            ) : (
              <>
                {userData?.address ? (
                  <h5>{userData.address}</h5>
                ) : (
                  <h5 style={{ color: " #1D1D1D4D" }}>Address</h5>
                )}
              </>
            )}
          </div>
          {
            editAddress === false && (
              <div
                className="edit_icon "
                onClick={() => setEditAddress(true)}
                style={{ padding: "6px", marginLeft: "40px" }}
              >
                <Pencil size={20} weight="light" color="#1D1D1DBF" />
              </div>
            )
          }

        </div>
        <hr />
      </div>
      {editAddress ||
        editBio ||
        editEmail ||
        editName ||
        editWebsite ||
        editMobile ? (
        <footer className="my-2  user_profile">
          <button onClick={() => saveData()}>Save Data</button>
        </footer>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditProfile;
