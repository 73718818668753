import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../Const/Url";
import Chats from "./ChatRoom/Chats";
import GroupChat from "./ChatRoom/GroupChat";
import NoChat from "./ChatRoom/NoChat";
import "./MainChat.css";
import AddNewPerticipaints from "./SideBar/AddNewPerticipaints";
import EditProfile from "./SideBar/Profile/EditProfile";
import FriendProfile from "./SideBar/Profile/FriendProfile";
import Profile from "./SideBar/Profile/Profile";
import Accounts from "./SideBar/Settings/Accounts";
import Help from "./SideBar/Settings/Help";
import NewChat from "./SideBar/Settings/NewChat";
import Notification from "./SideBar/Settings/Notification";
import Settings from "./SideBar/Settings/Settings";
import SideBar from "./SideBar/SideBar";
import Terms from "./SideBar/Terms/Terms";
import AboutAs from "./SideBar/About/AboutAs";
import ContactUs from "./SideBar/ContactUs/ContactUs";
import PrivacyPolicy from "./SideBar/PrivacyPolicy/PrivacyPolicy";

const ChatMainContainer = () => {
  const [index, setIndex] = useState(1);
  const [render, setRender] = useState("home");
  const [activeFnd, setActiveFnd] = useState(null);
  const [chatLoading, setChatLoading] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const token = localStorage.getItem("access_token");
  const [socketEvent, setSocketEvent] = useState();
  const [socket, setSocket] = useState(
    new WebSocket(
      `wss://backend.snowtex.org/ws/chat/${token}/`
    )
  );

  socket.onmessage = (event) => {
    if (event !== undefined) {
      const messageData = JSON.parse(event.data);
      const timeout = setTimeout(() => {
        getChats()
      }, 2000)
    }
    setSocketEvent(event);
    setIndex(index + 1);
  };

  socket.onopen = (event) => {
    console.log('Socket Open', event);
  }

  socket.onerror = (event) => {
    console.log('Soket Error', event);
  }

  socket.onclose = (event) => {
    console.log('Socket Close', event);
  }

  useEffect(() => {
    getChats();
  }, [pageNumber, index, render]);

  const getChats = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/?offset=${pageNumber}&limit=100`;
    axios
      .get(url)
      .then((res) => {
        const response = res.data.data.results;
        const newRes = response.map((person) => {
          return {
            ...person,
            group_name:
              person.group_name === null
                ? person?.receiver?.first_name +
                " " +
                person?.receiver?.last_name
                : person.group_name,
          };
        });
        setChatList(newRes);
        setChatLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };

  const getMoreData = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className="chat_parent ">
      <div className="side_bar">
        {render === "home" && (
          <SideBar
            getMoreData={getMoreData}
            chatList={chatList}
            chatLoading={chatLoading}
            setRender={setRender}
            activeFnd={activeFnd}
            setActiveFnd={setActiveFnd}
            render={render}
            socket={socket}
            socketEvent={socketEvent}
            getChats={getChats}
          />
        )}
        {render === "profile" && <Profile setRender={setRender} />}
        {render === "edit-profile" && <EditProfile setRender={setRender} />}
        {render === "contact" && (
          <NewChat
            setActiveFnd={setActiveFnd}
            chatList={chatList}
            setRender={setRender}
            socket={socket}
            setIndex={setIndex}
            socketEvent={socketEvent}
          />
        )}
        {render === "account" && <Accounts setRender={setRender} />}
        {render === "terms" && <Terms setRender={setRender} />}
        {render === "privacy-policy" && <PrivacyPolicy setRender={setRender} />}
        {render === "notification" && <Notification setRender={setRender} />}
        {render === "about-us" && <AboutAs setRender={setRender} />}
        {render === "contact-us" && <ContactUs setRender={setRender} />}
        {render === "help" && <Help setRender={setRender} />}
        {render === "create-group" && (
          <AddNewPerticipaints setRender={setRender} setIndex={setIndex} socket={socket} />
        )}
        {render === "friend-profile" && (
          <FriendProfile setRender={setRender} activeFnd={activeFnd} />
        )}
      </div>
      <div className="chats">
        {activeFnd === null && <NoChat />}
        {activeFnd !== null && activeFnd.type === 0 && (
          <Chats
            index={index}
            setIndex={setIndex}
            setRender={setRender}
            socket={socket}
            socketEvent={socketEvent}
            setSocketEvent={setSocketEvent}
            activeFnd={activeFnd}
          />
        )}
        {activeFnd !== null && activeFnd.type === 1 && (
          <GroupChat
            index={index}
            setIndex={setIndex}
            socket={socket}
            setRender={setRender}
            activeFnd={activeFnd}
            socketEvent={socketEvent}
          />
        )}
      </div>
    </div>
  );
};

export default ChatMainContainer;
