import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Check,
  Checks,
  User
} from "phosphor-react";
import React, { useRef, useState } from "react";
import "./Chatroom.css";

import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import docImg from "../../../assets/img/doc.png";
import excl from "../../../assets/logo/excel 1.svg";
import music from "../../../assets/logo/music_note.svg";
import vedio from "../../../assets/logo/vedio.svg";
import word from "../../../assets/logo/word 1.svg";
import { showToast } from "../../../utils/ToastHelper";
import { BASE_URL } from "../../Const/Url";
import NoChat from "./NoChat";
const Chats = ({ activeFnd, setRender, socket, index, setIndex, socketEvent, setSocketEvent }) => {
  const [show, setShow] = useState(false);
  const [currImg, setCurrImg] = useState(null);
  const [newData, setNewData] = useState([]);
  const [ackSend, setAckSend] = useState(0);
  const cUser = JSON.parse(localStorage.getItem("userData"));
  const previousItem = localStorage.getItem("previousItem");
  const history = useHistory();
  const containerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState([]);
  const [liveChat, setLiveChat] = useState([]);
  const [inputStr, setInputStr] = useState("");
  const [sendExcl, setSendExcl] = useState(null);
  const [sendWord, setSendWord] = useState(null);
  const [sendVedio, setSendVedio] = useState(null);
  const [sendAudio, setSendAudio] = useState(null);
  const [sendFile, setSendFile] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [attach, setAttach] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [dataStatus, setdataStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ndLoading, setndLoading] = useState(true);
  const [chat, setChat] = useState({});
  const [ofset, setOfset] = useState(0);
  const [userData, setUserData] = useState({});
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  useEffect(() => {
    if (socketEvent !== undefined) {
      const messageData = JSON.parse(socketEvent.data);
      if (messageData['data']['event'] === undefined) {
        if (messageData['data']['conversation'] === activeFnd.id) {
          const lastMessege = JSON.parse(socketEvent.data);
          const data = [...liveChat, lastMessege['data']];
          setLiveChat(data);
          setIndex(index + 1);
          const sender_id = lastMessege['data']['sender']['id'];
          const conv_id = lastMessege['data']['conversation'];
          const conv_msg_id = lastMessege['data']['id'];
          if (userData?.id !== sender_id) {
            socket.send(
              JSON.stringify({
                event: "message_open",
                data: {
                  conversation: conv_id,
                  message: conv_msg_id
                },
              })
            );
          }
        }
      } else if ((messageData['data']['event'] === "message_open" || messageData['data']['event'] === "message_received") && messageData['data']['data']['conversation'] === activeFnd.id) {
        // getLiveChat();
      }
    }

  }, [socketEvent]);

  useEffect(() => {
    if (socket !== null && newData !== undefined && newData.length !== 0 && ackSend !== activeFnd.id) {
      setAckSend(activeFnd.id);
      if (activeFnd.id === newData[newData?.length - 1]?.conversation && newData[newData?.length - 1]?.sender?.id !== cUser?.id) {
      }
    }
  }, [newData]);


  const handleClose = () => {
    setShow(false);
    setCurrImg(null);
  };
  const handleShow = () => setShow(true);

  const handleDownload = () => {
    saveAs(currImg, "image.jpg");
  };

  var token = localStorage.getItem("access_token");
  localStorage.setItem("activeFnd", activeFnd.id);

  const userName = userData?.first_name + " " + userData?.last_name;
  const userFirstName = userData?.first_name;
  const open2 = Boolean(anchorEl2);

  const onEmojiClick = (emojiData, event) => {
    const splitEmojiData = emojiData.unified.split('-')
    const emojiString = splitEmojiData
      .map(code => String.fromCodePoint(parseInt(code, 16)))
      .join('');

    setInputStr((prevInput) => prevInput + emojiString);
    setShowPicker(false);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    setOfset(0);
  }, [activeFnd.id]);


  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        setUserData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const response = JSON.parse(err.request.response);
        const message = response.message;

        if (
          err.response.code === 401 ||
          message === "Authentication credentials were not provided." ||
          message === "Invalid token."
        ) {
          localStorage.removeItem("is_logged_in");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userData");
          history.push("/login");
        }
      });
  }, []);

  useEffect(() => {
    if (ofset === 0) {
      setLiveChat([]);
      getLiveChat();
    } else {
      getLiveChat2();
      setLiveChat([...newData, ...liveChat]);
      setNewData([]);
    }
  }, [messages, activeFnd.id, activeFnd, ofset]);


  useEffect(() => {
    getSingleChat();
  }, [activeFnd]);

  useEffect(() => {
    containerRef?.current?.scrollIntoView();
  }, [messages]);

  const getSingleChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
    axios
      .get(url)
      .then((res) => {
        setChat(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;

      });
  };

  const getLiveChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/messages/?limit=30&offset=${ofset}`;
    axios
      .get(url)
      .then((res) => {
        const data = res?.data?.data?.results;
        const newData = data.reverse();
        setNewData(newData);
        let lastIdOneIndex = newData.length - 1;
        for (let i = newData.length - 1; i >= 0; i--) {
          if (newData[i].sender.id !== cUser.id) {
            lastIdOneIndex = i;
            break;
          }
        }

        const sender_id = newData[lastIdOneIndex]['sender']['id'];
        const conv_id = newData[lastIdOneIndex]['conversation'];
        const conv_msg_id = newData[lastIdOneIndex]['id'];

        let changedArray = newData.slice(0, lastIdOneIndex + 1);
        const changeStatus = changedArray.map((obj) => {
          return {
            ...obj,
            status: 2,
          };
        });
        let unchangedArray = newData.slice(lastIdOneIndex + 1);
        setLiveChat([...changeStatus, ...unchangedArray]);
        setChatCount(res.data.data.count);
        if (userData?.id !== sender_id) {
          socket.send(
            JSON.stringify({
              event: "message_open",
              data: {
                conversation: conv_id,
                message: conv_msg_id
              },
            })
          );
        }

        setndLoading(false);
      })

      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };

  const getLiveChat2 = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/messages/?limit=30&offset=${ofset}`;
    axios
      .get(url)
      .then((res) => {
        const data = res?.data?.data?.results;
        const newData = data;
        setNewData(newData.reverse());
        setChatCount(res.data.data.count);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };

  function handleScroll(event) {
    if (liveChat?.length === chatCount) {
      return;
    }
    if (liveChat?.length === chatCount) {
      event.target.removeEventListener("scroll", handleScroll);
    } else if (liveChat?.length === chatCount && event.target.scrollTop === 0) {
      event.target.removeEventListener("scroll", handleScroll);
    } else if (liveChat?.length <= chatCount) {
      if (event.target.scrollTop === 0) {
        setOfset(ofset + 30);
      }
    }
  }

  const handleClick = (str, id) => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    if (
      inputStr !== "" ||
      sendFile?.length > 0 ||
      sendVedio?.length > 0 ||
      sendAudio?.length > 0 ||
      sendWord?.length > 0 ||
      sendExcl?.length > 0
    ) {
      const data1 = new FormData();
      if (sendFile?.length > 0) {
        Object.values(sendFile).map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 0);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              showToast("error", message);
            });
        });
      } else if (sendVedio?.length > 0) {
        Object.values(sendVedio)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 1);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              showToast("error", message);
            });
        });
      } else if (sendAudio?.length > 0) {
        Object.values(sendAudio)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 2);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              showToast("error", message);
            });
        });
      } else if (sendWord?.length > 0) {
        Object.values(sendWord)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 3);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              showToast("error", message);
            });
        });
      } else if (sendExcl?.length > 0) {
        Object.values(sendExcl)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 3);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              showToast("error", message);
            });
        });
      } else {
        socket.send(
          JSON.stringify({
            event: "message_send",
            data: {
              conversation: id,
              message: inputStr,
              attachments: [],
              sticker: null,
              message_type: 0,
            },
          })
        );
      }
    } else {
      showToast("error", "Please Type Something!");
    }

    setOfset(0);
    setInputStr("");
    setSendFile("");
    setSendVedio("");
    setSendAudio("");
    setSendExcl("");
    setSendWord("");
  };

  const timeFunction = (time) => {
    try {
      const date = new Date(time);
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedTime = formatter?.format(date);
      return formattedTime;
    } catch (error) {
      return "";
    }
  };
  const dataRemove = () => {
    setInputStr("");
    setSendFile("");
    setSendVedio("");
    setSendAudio("");
    setSendExcl("");
    setSendWord("");
  };
  const handleSend = (e) => {
    if (
      inputStr ||
      sendAudio ||
      sendExcl ||
      sendVedio ||
      sendWord ||
      sendFile
    ) {
      if (e.keyCode == 13) {
        handleClick(inputStr, chat.id);
        dataRemove();
      }
    }
  };
  const gDate = (d) => {
    return d?.created_at?.split("T")[0];
  };
  let lastIdOneIndex = liveChat.length - 1;

  if (ndLoading) {
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <CircularProgress color="inherit" />
    </div>;
  }
  if (isLoading) {
    return <NoChat />;
  }

  return (
    <>
      <div className="chat-container">
        <div className="sidebar_hedar hedaer_shadow sidebar_header_right2">
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Avatar
              onClick={() => setRender("friend-profile")}
              src={chat?.receiver?.image_url}
            />{" "}
            <div className="ms-2  left_user">
              <span className="ms-2" style={{ fontSize: "19px" }}>
                {chat?.receiver?.first_name + " " + chat?.receiver?.last_name}
              </span>

              <p className="chat_text pt-2" style={{ marginLeft: "7px" }}>
                {chat?.receiver?.is_online === true
                  ? "online"
                  : chat?.receiver?.is_online === false &&
                    chat?.receiver?.last_seen !== null
                    ? `Last Seen ${timeFunction(chat?.receiver?.last_seen)}`
                    : `Last Seen  today`}
              </p>
            </div>
          </div>
          <div className="sidebar_header_right">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open2 ? "long-menu" : undefined}
              aria-expanded={open2 ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick2}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              className="pop_over3"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                style: {
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  width: "22ch",
                  marginRight: "10ch",
                  top: "100px",
                  padding: "10px ",
                },
              }}
            >
              <MenuItem
                className="privacy my-2"
                onClick={() => {
                  setRender("friend-profile");
                  handleClose2();
                }}
              >
                {" "}
                <User size={28} className="me-3" />
                View Profile
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="chat-body mt-2 " onScroll={handleScroll}>
          {liveChat?.length > 0 ? (
            liveChat?.map((chat, index) => (
              <>
                {gDate(liveChat[index - 1]) !== gDate(chat) && (
                  <div className="d-flex mb-2 justify-content-center">
                    <span style={{ textAlign: "center", fontSize: "12px" }}>
                      {gDate(chat)}
                    </span>
                  </div>
                )}

                <div
                  className={`messege ${chat?.sender?.first_name + " " + chat?.sender?.last_name ===
                    userName
                    ? "my_messege2nd"
                    : "fnd_messege2nd"
                    } `}
                  style={{
                    display: `${chat?.messege?.length === 0 && "none"}`,
                  }}
                >
                  {chat?.message?.length === 0 ? (
                    <>
                      {" "}
                      {chat?.sender?.first_name +
                        " " +
                        chat?.sender?.last_name ===
                        userName ? (
                        <>
                          {chat?.attachments?.length > 0 && (
                            <>
                              <p className="image_div_last">
                                {chat?.attachments[0]?.doc_type === 0 && (
                                  <img
                                    onClick={() => {
                                      setCurrImg(chat?.attachments[0]?.doc_url);
                                      handleShow();
                                    }}
                                    style={{
                                      borderRadius: "10px",
                                      objectFit: "cover",
                                    }}
                                    width={200}
                                    height={200}
                                    src={chat?.attachments[0]?.doc_url}
                                    alt=""
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 1 && (
                                  <video
                                    controls
                                    src={chat?.attachments[0]?.doc_url}
                                    style={{ borderRadius: "10px" }}
                                    width={200}
                                    height={200}
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 2 && (
                                  <audio
                                    controls
                                    src={chat?.attachments[0]?.doc_url}
                                    style={{ borderRadius: "10px" }}
                                    width={150}
                                    height={50}
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 3 && (
                                  <img
                                    onClick={() => {
                                      const fileName =
                                        chat?.attachments[0]?.doc_url
                                          ?.split("/")
                                          .pop();
                                      const aTag = document.createElement("a");
                                      aTag.href = chat?.attachments[0]?.doc_url;
                                      aTag.setAttribute("download", fileName);
                                      document.body.appendChild(aTag);
                                      aTag.click();
                                      aTag.remove();
                                    }}
                                    width={60}
                                    src={docImg}
                                    alt=""
                                  />
                                )}

                                <span className="image_span_last mt-1">
                                  {timeFunction(chat?.created_at)}{" "}
                                  {chat?.sender?.first_name +
                                    " " +
                                    chat?.sender?.last_name ===
                                    userName && (
                                      <>
                                        {chat?.status === 0 && (
                                          <Check
                                            size={17}
                                            weight="light"
                                            color="black"
                                            style={{ marginBottom: "3px" }}
                                          />
                                        )}
                                        {chat?.status === 1 && (
                                          <Checks
                                            size={17}
                                            weight="light"
                                            color="black"
                                            style={{ marginBottom: "3px" }}
                                          />
                                        )}
                                        {chat?.status === 2 && (
                                          <Checks
                                            size={17}
                                            weight="light"
                                            color="blue"
                                            style={{ marginBottom: "3px" }}
                                          />
                                        )}{" "}
                                      </>
                                    )}
                                </span>
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p className="image_div_last">
                            {chat?.attachments[0]?.doc_type === 0 && (
                              <img
                                onClick={() => {
                                  setCurrImg(chat?.attachments[0]?.doc_url);
                                  handleShow();
                                }}
                                style={{
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                }}
                                width={200}
                                height={200}
                                src={chat?.attachments[0]?.doc_url}
                                alt=""
                              />
                            )}
                            {chat?.attachments[0]?.doc_type === 1 && (
                              <video
                                controls
                                src={chat?.attachments[0]?.doc_url}
                                style={{ borderRadius: "10px" }}
                                width={200}
                                height={200}
                              />
                            )}
                            {chat?.attachments[0]?.doc_type === 2 && (
                              <audio
                                controls
                                src={chat?.attachments[0]?.doc_url}
                                style={{ borderRadius: "10px" }}
                                width={100}
                                height={30}
                              />
                            )}
                            {chat?.attachments[0]?.doc_type === 3 && (
                              <img
                                onClick={() => {
                                  const fileName = chat?.attachments[0]?.doc_url
                                    ?.split("/")
                                    .pop();
                                  const aTag = document.createElement("a");
                                  aTag.href = chat?.attachments[0]?.doc_url;
                                  aTag.setAttribute("download", fileName);
                                  document.body.appendChild(aTag);
                                  aTag.click();
                                  aTag.remove();
                                }}
                                width={60}
                                src={docImg}
                                alt=""
                              />
                            )}

                            <span className="image_span_last mt-1">
                              {timeFunction(chat?.created_at)}
                            </span>
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <p>
                      {chat?.message}
                      <span>
                        {timeFunction(chat?.created_at)}{" "}
                        {chat?.sender?.first_name +
                          " " +
                          chat?.sender?.last_name ===
                          userName && (
                            <>
                              {chat?.status === 0 && (
                                <Check
                                  size={17}
                                  weight="light"
                                  color="black"
                                  style={{ marginBottom: "3px" }}
                                />
                              )}
                              {chat?.status === 1 && (
                                <Checks
                                  size={17}
                                  weight="light"
                                  color="black"
                                  style={{ marginBottom: "3px" }}
                                />
                              )}
                              {chat?.status === 2 && (
                                <Checks
                                  size={17}
                                  weight="light"
                                  color="blue"
                                  style={{ marginBottom: "3px" }}
                                />
                              )}{" "}
                            </>
                          )}
                      </span>
                    </p>
                  )}
                </div>
              </>
            ))
          ) : (
            <>
              <p
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{ color: "white" }}
              >
                No chat{" "}
              </p>
            </>
          )}
          <div ref={containerRef}></div>
        </div>

        {attach === true && (
          <div className="attach_container">
            <div
              className="excl"
              style={{ background: "#31A01F" }}
            >
              <img src={excl} alt="" />
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  setSendExcl(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="word"
              style={{ background: "#4797EE" }}
            >
              <img src={word} alt="" />
              <input
                type="file"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(e) => {
                  setSendWord(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="vedio"
              style={{ background: "#A410C9" }}
            >
              <img src={vedio} alt="" />
              <input
                type="file"
                accept="video/*"
                onChange={(e) => {
                  setSendVedio(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="music"
              style={{ background: "#FF7F23" }}
            >
              <img src={music} alt="" />
              <input
                type="file"
                accept="audio/*"
                onChange={(e) => {
                  setSendAudio(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
          </div>
        )}

        <footer className="py-3 px-3">
          <div className="d-flex">
            <IconButton
              className="emoji_btn "
              onClick={() => setShowPicker(!showPicker)}
            >
              <EmojiEmotionsOutlinedIcon />
            </IconButton>
            {showPicker && (
              <EmojiPicker
                pickerStyle={{ position: "absolute", top: "60px" }}
                onEmojiClick={onEmojiClick}
              />
            )}
            <IconButton
              className="emoji_btn ms-2"
              onClick={() => setAttach(!attach)}
            >
              <AttachFileOutlinedIcon />
            </IconButton>

            <div className="input_footer">
              {sendFile ? (
                <>
                  <div className="imageDiv">Image Selected</div>
                </>
              ) : sendVedio ? (
                <>
                  <div className="imageDiv">Vedio Selected</div>
                </>
              ) : sendAudio ? (
                <>
                  <div className="imageDiv">Audio Selected</div>
                </>
              ) : sendExcl || sendWord ? (
                <>
                  <div className="imageDiv">Document Selected</div>
                </>
              ) : (
                <input
                  type="text"
                  onChange={(e) => setInputStr(e.target.value)}
                  placeholder="Type a Message"
                  onKeyDown={handleSend}
                  value={inputStr}
                />
              )}
            </div>
            <IconButton
              onClick={() => handleClick(inputStr, chat.id)}
              className="send_btn ms-2"
            >
              <SendOutlinedIcon />
            </IconButton>
          </div>
        </footer>
        <>
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={currImg}
                width={350}
                height={300}
                style={{ objectFit: "cover", borderRadius: "5px" }}
                alt=""
              />
              <div className="my-2 download">
                <button
                  target="_blank"
                  onClick={handleDownload}
                  className="px-3 py-2 "
                  style={{
                    border: "1px solid blue",
                    outline: "none",
                    borderRadius: "5px",
                    color: "blue",
                  }}
                >
                  Download
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </>
  );
};

export default Chats;
