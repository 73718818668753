
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { BASE_URL } from "../../../Const/Url";

export default function Terms({ setRender, socket, setIndex }) {
  const [query, setQuery] = useState("");
  const [terms, setTerms] = useState();
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const getTerms = () => {
    const url = `${BASE_URL}api/v1/utility/mobile/page/2/`
    axios
      .get(url)
      .then((res) => {
        const data = res?.data?.data;

        setTerms(data);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };
  useEffect(() => {

    getTerms()
  }, []);
  return (
    <>
      <div className="profile_main new_perticipate">
        <div className="profile_hedaer">
          <div className="profile_top">
            <IconButton onClick={() => setRender("home")}>
              <ArrowBackIcon />
            </IconButton>
            <h3 style={{ fontSize: "22px" }}>{terms?.title ? terms?.title : "Terms & Condition"}</h3>
          </div>
        </div>
        <div
          className="sidebar_search "
          style={{ background: "white", padding: " 14px" }}
        >
          <div className="search serch2">
            <SearchIcon />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}
              placeholder="Search "
            />
          </div>
        </div>
        <div className="p-5">
          <h2 className="text-center pb-3">{terms?.title}</h2>
          <p style={{ textAlign: "justify" }} >
            {terms?.desc.length > 1200 ? (
              <>
                {showMore ? terms?.desc : `${terms.desc.substring(0, 1200)}...`}
                <a href="#" onClick={(e) => { e.preventDefault(); toggleShowMore(); }}>
                  {showMore ? 'See Less' : 'See More'}
                </a>
              </>
            ) : (
              terms?.desc
            )}
          </p>
        </div>
      </div >
    </>
  )
}
