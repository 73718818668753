import { IconButton } from '@mui/material'
import { BellSimple, BellSlash } from 'phosphor-react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from 'react'

const Notification = ({ setRender }) => {
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={()=>setRender('privacy-policy')}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "22px" }}>Notification</h3>
        </div>
      </div>
      <div className="settings_details">
        <hr style={{color:'white'}} />
        <div className="settings_div">
          <div className="settings_icon">
            <BellSimple color="white" size={22} weight="light" />
          </div>
          <h5>On</h5>
        </div>
        <hr />
        <div className="settings_div">
          <div className="settings_icon">
            <BellSlash color="white" size={22} weight="light" />
          </div>
          <h5>Off</h5>
        </div>
        <hr />
      </div>
    </div>
  )
}

export default Notification