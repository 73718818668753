import React, { useState } from "react";
import verify from "../../assets/logo/Verify.svg";
import tic from "../../assets/logo/tick.svg";
import "./Authentication.css";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { showToast } from "../../utils/ToastHelper";
import { BASE_URL } from "../Const/Url";
import axios from "axios";

const Verify = () => {
  const history = useHistory();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOtp, setActiveOtp] = useState(0);
  const [finalOtp, setFinalOtp] = useState(null);
  const inputRef = useRef();
  const handleOtpChange = ({ target }, index) => {
    const { value } = target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    if (!value) setActiveOtp(index - 1);
    else setActiveOtp(index + 1);
    setOtp(newOtp);
    setFinalOtp(newOtp.join());
  };

  const handleKeyDown = ({ key }, index) => {
    if (key === "Backspace") setActiveOtp(index - 1);
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtp]);

  const login = () => {
    const mobile = localStorage.getItem("userNumber");
    const pass = localStorage.getItem("userPass");
    const url2 = `${BASE_URL}api/v1/auth/login/`;
    const loginData = {
      mobile: mobile,
      password: pass,
    };
    axios
      .post(url2, loginData)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Varification Successful.");
          // Store data's to local storage
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          localStorage.removeItem("userPass");
          if (localStorage.getItem("is_logged_in")) {
            history.push("/");
          }
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        showToast("error", message);
      });
  };

  const submitOtp = () => {
    const mobile = localStorage.getItem("userNumber");
    if (finalOtp?.length !== 11) {
      showToast("error", "Otp Should Be 6 Digit");
    } else {
      const data = {
        code: `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`,
        mobile: mobile,
      };
      const url = `${BASE_URL}api/v1/auth/account/verify/`;
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            login()
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
          showToast("error", message);
        });
    }
  };

  const resendOtp = () => {
    const url = `${BASE_URL}api/v1/auth/verification/resend/`;
    const mobile = localStorage.getItem("userNumber");
    const data = {
      mobile : mobile
    }
    axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            showToast("success", 'OTP resended');
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
          showToast("error", message);
        });
  };
  return (
    <div className="login_main">
      <div>
        <div className="logo_image" style={{ position: "relative" }}>
          <img className="img-fluid" src={verify} alt="" />
          <img className="img-fluid tic" src={tic} alt="" />
        </div>
        <h3 className="login_text">Verify Code</h3>
        <span className="verify-text">
          Please enter your 6 digit code sent to your phone
        </span>
        <div className="code_div d-flex justify-content-between mt-3">
          {otp.map((_, index) => (
            <div key={index} className='mx-1'>
              <input
                value={otp[index]}
                ref={index === activeOtp ? inputRef : null}
                type="number"
                style={{ textAlign: "center" }}

                onChange={(e) => handleOtpChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </div>
          ))}
        </div>
        <p className="last_text mt-3 text-center">
          Don’t receive code? <span className="send_again" onClick={resendOtp}>Sent again</span>
        </p>
        <div className="input_container" style={{ marginTop: "37px" }}>
          <button
            className="w-100  btn-outline-none"
            onClick={() => submitOtp()}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default Verify;
