import { Camera, Checks, Users, UsersThree } from "phosphor-react";
import React, { useEffect, useState } from "react";
import active from "../../../assets/logo/active.svg";
import active2 from "../../../assets/logo/active2.svg";
import active3 from "../../../assets/logo/activeFe.svg";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmojiPicker from "emoji-picker-react";
import "./SideBar.css";
import { IconButton } from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import Picker from "emoji-picker-react";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { width } from "@mui/system";
import { showToast } from "../../../utils/ToastHelper";

const AddNewPerticipaints = ({ setRender ,socket,setIndex}) => {
  const getUser = JSON.parse(localStorage.getItem("userData"));
  const [selectedImages, setSelectedImages] = useState([]);
  const [image, setImage] = useState(null);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [query, setQuery] = useState("");
  const keys = ["first_name", "last_name"];

  const onEmojiClick = (emojiData, event) => {
    setInputStr(
      (prevInput) => prevInput + String.fromCodePoint(`0x${emojiData.unified}`)
    );
    setShowPicker(false);
  };
  const [component, setComponent] = useState(true);
  const [group, setGroup] = useState([]);
  const [chatList, setChatList] = useState([]);
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    setImage(event.target.files[0]);

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  const [pageNumber, setPageNumber] = useState(1);
  const getChats = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/contact/`
    axios
      .get(url)
      .then((res) => {
        const data = res?.data?.data?.filter((data) => data?.mobile !== getUser?.mobile)
        setChatList([...chatList, ...data]);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };
  const getData = chatList?.filter((p) =>
    keys.some((key) =>
      p[key]?.toString()?.toLowerCase()?.includes(query)
    )
  );

  useEffect(() => {
    getChats();
  }, [pageNumber]);
 ;
  const createGroup = (id) => {
    if (group && group.includes(id) === false) {
      setGroup([...group, id]);
    } else if (group && group.includes(id) === true) {
      const filter = group.filter((d) => d !== id);
      setGroup(filter);
    }
  };
  const removeUser = (id) => {
    if (group && group.includes(id) === true) {
      const filter = group.filter((d) => d !== id);
      setGroup(filter);
    }
  };

  const addGroup = () => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/chat/mobile/conversation/`;
    const data1 = new FormData();
    data1.append("document", image);
    data1.append("doc_type", 0);
    axios
      .post(url1, data1)
      .then((res) => {
        if (res.data.status) {
          const testData = {
            group_image: res.data.data.id,
            group_name: inputStr,
            convo_type: 1,
            admins: [1],
            is_active: true,
            users: group.map((g) => g.id),
          };
          socket.send(
            JSON.stringify({
              event: "create_convo",
              data: testData
            })
          );
          showToast("success", "Group Create Successfully.");
          setRender("home");
          setIndex(prev=> prev+1)
          
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        showToast("error", message);
      });
  };
  return (
    <div>
      {component ? (
        <div className="profile_main new_perticipate">
          <div className="profile_hedaer">
            <div className="profile_top">
              <IconButton onClick={() => setRender("home")}>
                <ArrowBackIcon />
              </IconButton>
              <h3 style={{ fontSize: "22px" }}>Add Group Participants</h3>
            </div>
          </div>
          <div
            className="sidebar_search "
            style={{ background: "white", padding: " 14px" }}
          >
            <div className="search serch2">
            <SearchIcon />
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}
                placeholder="Search "
              />
            </div>
          </div>
          {group && (
            <div className="group_perticapite ">
              {group.map((g, index) => (
                <div
                  className={`perticipate ${
                    index > 0 && index % 2 === 1 && "ml-15"
                  }`}
                >
                  <div className="img_name">
                    <img
                      src={g.image_url}
                      style={{ borderRadius: "50%" }}
                      width={32}
                      alt=""
                    />
                    <p className="mx-3 my-auto">{g.first_name}</p>
                  </div>
                  <CloseIcon onClick={() => removeUser(g)} />
                </div>
              ))}
            </div>
          )}

          <div className="sidebar_chats new_perticipate_users mt-4">
            {getData?.map((user) => (
              <div
                key={user.id}
                className="single_chat "
                onClick={() => createGroup(user)}
                style={{ padding: "10px 16px", cursor: "pointer" }}
              >
                <div className="d-flex align-items-center">
                  <div className="active_container">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={user?.image_url}
                      alt=""
                    />
                    {group.includes(user) && (
                      <div className="tic_icon">
                        <DoneIcon />
                      </div>
                    )}
                  </div>

                  <div className="ms-4 d-flex flex-column justify-content-center ">
                    <h5 className="chat_name">
                      {user?.first_name + " " + user?.last_name}
                    </h5>
                    <span className="chat_text">Last seen within a month</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {group.length > 1 && (
            <footer className="group_footer">
              <div>
                <IconButton onClick={() => setComponent(false)}>
                  <ArrowForwardIcon />
                </IconButton>
              </div>
            </footer>
          )}
        </div>
      ) : (
        <div className="new_perticipate" style={{ position: "relative" }}>
          <div className="profile_hedaer">
            {showPicker && (
              <EmojiPicker
                pickerStyle={{ position: "absolute", top: "60px" }}
                onEmojiClick={onEmojiClick}
              />
            )}
            <div className="profile_top">
              <IconButton onClick={() => setRender("home")}>
                <ArrowBackIcon />
              </IconButton>
              <h3 style={{ fontSize: "22px" }}>New Chat</h3>
            </div>
          </div>
          <div className="group_photo_container">
            <div className="group_photo">
              {image ? (
                <img
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  src={selectedImages[selectedImages.length - 1]}
                  alt=""
                />
              ) : (
                <div className="top_div2">
                  <Camera size={45} weight="light" color="white" /> <br />
                  <span>ADD GROUP PHOTO</span>
                </div>
              )}
              {/*  */}

              <input
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                name="images"
              />
            </div>
          </div>
          <div>
            <div className="group_name">
              <input
                type="text"
                value={inputStr}
                onChange={(e) => setInputStr(e.target.value)}
                placeholder="Type Group Name Here"
                style={{ color: "black" }}
              />
              <IconButton onClick={() => setShowPicker(!showPicker)}>
                <EmojiEmotionsOutlinedIcon />
              </IconButton>
            </div>
          </div>
          <p className="text-center mt-4 member">
            Selected Members: {group?.length}{" "}
          </p>
          <div className="group_perticapite2 mx-auto">
            {group &&
              group.map((gr, index) => (
                <div
                  className={`perticipate perticipate2 ${
                    index > 0 && index % 2 === 1 && "ml-15"
                  }`}
                >
                  <div className="img_name">
                    <img style={{borderRadius:'50%'}} src={gr?.image_url} width={32} alt="" />
                    <p className="mx-3 my-auto">
                      {gr.first_name + " " + gr.last_name}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <div className="user_profile mt-4" style={{ marginTop: "40px" }}>
            <button onClick={addGroup}>Save Group</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewPerticipaints;
