import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { BASE_URL } from "../../../Const/Url";

export default function PrivacyPolicy({ setRender, socket, setIndex }) {
    const [query, setQuery] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState();
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const getPrivacyPolicy = () => {
        const url = `${BASE_URL}api/v1/utility/mobile/page/1/`
        axios
            .get(url)
            .then((res) => {
                const data = res?.data?.data;

                setPrivacyPolicy(data);
            })
            .catch((err) => {
                const message = JSON.parse(err.request.response).message;
            });
    };
    useEffect(() => {

        getPrivacyPolicy()
    }, []);
    return (
        <>
            <div className="profile_main new_perticipate">
                <div className="profile_hedaer">
                    <div className="profile_top">
                        <IconButton onClick={() => setRender("home")}>
                            <ArrowBackIcon />
                        </IconButton>
                        <h3 style={{ fontSize: "22px" }}>{privacyPolicy?.title ? privacyPolicy?.title : "Privacy Policy"}</h3>
                    </div>
                </div>
                <div
                    className="sidebar_search "
                    style={{ background: "white", padding: " 14px" }}
                >
                    <div className="search serch2">
                        <SearchIcon />
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}
                            placeholder="Search "
                        />
                    </div>
                </div>
                <div className="p-4">
                    <h2 className="text-center pb-3">{privacyPolicy?.title}</h2>
                    <p style={{ textAlign: "justify" }} >


                        {privacyPolicy?.desc.length > 1200 ? (
                            <>
                                {showMore ? privacyPolicy?.desc : `${privacyPolicy.desc.substring(0, 1200)}...`}
                                <a href="#" onClick={(e) => { e.preventDefault(); toggleShowMore(); }}>
                                    {showMore ? 'See Less' : 'See More'}
                                </a>
                            </>
                        ) : (
                            privacyPolicy?.desc
                        )}
                    </p>
                </div>
            </div >
        </>
    )
}
