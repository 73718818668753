import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DeviceMobile, Question, Trash, TrashSimple } from "phosphor-react";
import { BASE_URL } from "../../../Const/Url";
import axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const Accounts = ({ setRender }) => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("is_logged_in")) {
      history.push("/");
    } else {
      history.push("/login");
    }
  }, []);
  const deleteAccount = () => {
    const url = `${BASE_URL}api/v1/auth/delete/`;
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover profile picture!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .get(url)
          .then((res) => {
            if (res.data.status) {
              history.push("/login");
              localStorage.removeItem("is_logged_in");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userData");
              showToast("success", "Deleted Successfully");
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }
            showToast("error", message);
          });
      } else {
        swal("Your profile  is safe!");
      }
    });
  };
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("privacy-policy")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "22px" }}>Account</h3>
        </div>
      </div>
      <div className="settings_details">
        <hr style={{ color: "white" }} />
        <div className="settings_div">
          <div className="settings_icon">
            <DeviceMobile color="white" size={22} weight="light" />
          </div>
          <h5>Change Number</h5>
        </div>
        <hr />
        <div className="settings_div" onClick={deleteAccount}>
          <div className="settings_icon">
            <TrashSimple color="white" size={22} weight="light" />
          </div>
          <h5>Delete Account</h5>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Accounts;
