import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../../../Const/Url";
import axios from "axios";
import { Envelope, Phone, User } from "phosphor-react";
import { Modal } from "react-bootstrap";

const FriendProfile = ({ setRender, activeFnd }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(true);
  const [friend, setFriend] = useState({});
  const getSingleChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
    axios
      .get(url)
      .then((res) => {
        setFriend(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };

  useEffect(() => {
    getSingleChat();
  }, [activeFnd]);
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "24px" }}>Contact View</h3>
        </div>
      </div>
      <div className="user_profile3 ">
        <div
          className="user_img_div user_profile_new_div"
          style={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          <img
            className="img-fluid "
            src={
              friend?.convo_type === 0
                ? friend?.receiver.image_url
                : friend?.group_image_url
            }
            alt=""
          />
        </div>
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color:'#1F5DA0'}} center id="contained-modal-title-vcenter">
           Contact Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img
              width={300}
              height={400}
              style={{ objectFit: "cover", borderRadius: "10px" }}
              src={
                friend?.convo_type === 0
                  ? friend?.receiver.image_url
                  : friend?.group_image_url
              }
              alt=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      <div className="user_info mt-4">
        <div className="name">
          <User size={36} weight="light" color="#1F5DA0" />
          <h5>
            {friend?.convo_type === 0
              ? friend?.receiver.first_name + " " + friend?.receiver.last_name
              : friend?.group_name}
          </h5>
        </div>
        {friend?.convo_type === 0 && (
          <>
            <hr />
            <div className="name">
              <Phone size={36} weight="light" color="#1F5DA0" />
              <h5>{friend?.receiver.mobile}</h5>
            </div>
          </>
        )}
        <hr />
      </div>
    </div>
  );
};

export default FriendProfile;
