import React, { useState } from "react";
import "./Authentication.css";
import user from "../../assets/logo/user.svg";
import cam from "../../assets/logo/cam.svg";
import { Envelope, LockKey, LockKeyOpen, Phone, User } from "phosphor-react";
import { RiErrorWarningLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../Const/Url";

const CreateAccount = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [numberValidation, setNumberValidation] = useState("+880");
  const [passValidation, setPassValidation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [finalPass, setFinalPass] = useState("");
  const [email, setEmail] = useState("example@email.com");
  const history = useHistory();
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [eye, setEye] = useState(false);
  const [eye2, setEye2] = useState(false);
  const changeEye = () => {
    setEye((prev) => !prev);
  };
  const changeEye2 = () => {
    setEye2((prev) => !prev);
  };
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages(imagesArray);
    // FOR BUG IN CHROME
    event.target.value = "";
  };
  const createAccount = () => {
    const data = new FormData();
    const url = `${BASE_URL}api/v1/auth/signup/`;
    const userData = {
      first_name: firstName,
      last_name: lastName,
      dob: "2023-01-16",
      email: email,
      mobile: numberValidation,
      password: passValidation,
      confirm_password: finalPass,
      country: 20,
      gender: 0,
    };
    if (firstName === "") {
      showToast("error", "Enter First Name Please!");
    } else if (lastName === "") {
      showToast("error", "Enter Last Name Please!");
    } else if (!email.match(mailformat)) {
      showToast("error", "Enter Valid Email Please!");
    }
    else if (numberValidation?.length !== 14) {
      showToast("error", " Enter Valid Number Please !");
    }
    else if (passValidation?.length === 0 || passValidation?.length < 8) {
      showToast("error", "Enter Minimum 8 Digit Password !");
    }
    else if (passValidation !== finalPass) {
      showToast("error", "Confirm Password Did Not Match !");
    }

    else {
      axios
        .post(url, userData)
        .then((res) => {
          if (res.data.status) {
            showToast("success", "Send Verification.");
            localStorage.setItem("userNumber", numberValidation);
            localStorage.setItem("userPass", passValidation);
            history.push("/verify");
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
        });
    }

  };
  return (
    <div className="login_main">
      <div>
        <h3 className="login_text">Create new account </h3>
        <div>
          <div className="logo_image ">
            <div style={{ position: "relative" }}>
              {selectedImages.length > 0 ? (
                selectedImages.map((image) => {
                  return (
                    <img
                      width={90}
                      height={95}
                      className="img-fluid rounded-circle"
                      src={image}
                      alt=""
                    />
                  );
                })
              ) : (
                <>
                  <img className="img-fluid" src={user} alt="" />
                </>
              )}
            </div>
          </div>
          <div className="input_container create mt-3 d-flex justify-content-between name">
            <div className="number number2">
              <div className="icons-div">
                <User size={24} color="white" weight="light" />
              </div>
              <input
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                name=""
                id=""
              />
            </div>
            <div className="number number2">
              <input
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                name=""
                id=""
              />
            </div>
          </div>
          <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <Envelope size={24} color="white" weight="light" />
              </div>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                name=""
                id=""
                value={email}
              />
            </div>
            {!email?.match(mailformat) && email?.length > 0 && (
              <span>
                <RiErrorWarningLine /> invalid Email
              </span>
            )}
          </div>
          <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <Phone size={24} color="white" weight="light" />
              </div>
              <input
                type="text"
                placeholder="Phone Number"
                name=""
                id=""
                autocomplete="off"
                value={numberValidation}
                onChange={(e) => setNumberValidation(e.target.value)}
              />
            </div>
            {numberValidation?.length > 4 &&
              numberValidation?.length !== 14 && (
                <span>
                  <RiErrorWarningLine /> Must Be 11 characters
                </span>
              )}
          </div>
          <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <LockKey size={24} color="white" weight="light" />
              </div>
              <input
                type={eye ? "text" : "password"}
                onChange={(e) => setPassValidation(e.target.value)}
                placeholder="Password"
                name=""
                id=""
                autocomplete="off"
              />
              <div onClick={() => changeEye()}>
                {eye ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
            {passValidation?.length > 0 && passValidation?.length < 8 && (
              <span>
                <RiErrorWarningLine /> Minimum 8 characters
              </span>
            )}
          </div>

          <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <LockKeyOpen size={24} color="white" weight="light" />
              </div>
              <input
                autocomplete="off"
                type={eye2 ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={(e) => setFinalPass(e.target.value)}
                name=""
                id=""
              />
              <div onClick={() => changeEye2()}>
                {eye2 ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
            <div className="create_redirect">
              <span style={{color:"#212121"}}>Already Have an account ?</span><span className="colord" onClick={() => history.push('/login')}>Sign In</span>
            </div>
            {finalPass?.length > 0 && finalPass !== passValidation && (
              <span>
                <RiErrorWarningLine />
                Password Didn't Match !
              </span>
            )}
          </div>

          <div className="input_container" style={{ marginTop: "37px" }}>
            <button onClick={createAccount} className="w-100  btn-outline-none">
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
